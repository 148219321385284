export const gifs = [
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExamFtdDc3NDZhc3Y1ajdycmw4cmRwdHZ0azBubWFzYjc0bzB0ZjVxNSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/BPJmthQ3YRwD6QqcVD/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExaHhtNWZ4bWJzYTdteXY2MGZ0cGFqNjJoNjk3YjlwdWJrZmYwY3gyNCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3o6fJ1BM7R2EBRDnxK/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExMmY5dTd1eXp1ODgwd3kzaGdhc3pjaWpob3RtM2t0dmhiZzhrb3VtOCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3o6fIUZTTDl0IDjbZS/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExb2l2Y3phNHp5djRucW16bnNzaTFibWV0cWRoZDB6YXh2MHMyZzIwaCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/H1NIKdfygAAMruqArl/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExdjE2MTZyMHc3czJ3M3FyaWtubmthNTBwZnE5b3JuNm4xaXJmMWhjcSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/fdyZ3qI0GVZC0/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExNnNjNWIzaGN4ODZoOTF6bWVvYnViY3N2enN4ODFsbGJ0bm95N2l0cCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/wZjlCH43M3M0U/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExNDdzZ3FhcjN4d2c1eHo0amZwZnhjbzJ2Z3QyeGRoNjRxMGc0NTg3MiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/wue4QtxncWuE8/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExMmduYTNoZTFid3dtbWtmaTlpaDBrbWx6OHgxOXE5cWZpdWRucHNzeiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/zSzYBsqf6hUkkOM206/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExYzdwdGxpMzlidjgxdGh1dHV3ejRtOXRuajJranlxN3hhYnB6OXJzYyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/znodP9eurvThe/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExeDE3MDU5OTliNnBpM2tuNGJpenNlaGIxazVpNmNxNjl3ODM3Z210ZyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3MibWRcT0AOYG7HUeT/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExZGE3ZGwyNzQwcDEybXp2b3BlZmNleWQxY2d4Y2R0OXdydTNsbHBjciZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/2be3wzOOEh7rGTmGLo/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExOHU5dGFqNm93YXVoN3hocnMwc2tnMWM5cjM0ZHh5NnVycHRmMHRnNyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/hv14mGOF3MY7wDKPkE/giphy-downsized-large.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExdXI2cXRqcmRyODI0MzVrNWY1cDI2OXFua2R2ZjFsdWp5dHV3ZnczdCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/S6qkS0ETvel6EZat45/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExOWk4eW9sNzRub3B4emJ4bzV3OWZqM3Eyd3hyeXNyZDc0NjByd2hreiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3oz8xAFtqoOUUrsh7W/giphy.gif',
    'https://media.giphy.com/media/cOvgh3VjLmeg8LLBtk/giphy-downsized-large.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExZDd2ejI0eG45bGRwN3VhcTVzd3V5aHdnamY3YjJvbHFyaTR1MGh6NiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/ruFFE06RFWOF66nk9V/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExZTBmMHoxZTBpeWpobXc3NWlhZW11N2w0ZjZxb2pwZXJmZjU4MXFkcyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/gg1oxesicLSWk/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExbW5zNWdzemRnanlnaDVraGlla3NtMHJxeGJncWpvZWxvMXRubTJyZyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/ZXBCQjBvOJ5bQXP95h/giphy.gif',
    'https://media.giphy.com/media/nbvFVPiEiJH6JOGIok/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExNmZtamdnYmx6OGw2Y3R6cjFnYTNwODJhaTFmc3c4dmsxNG8xYThoayZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/bfUCBgkj73arw2LUNe/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExODIwcDZ5c3d0d28xN2p5aXFyZDY3cDNsdng2aGFkZnJtcnhnb2tsciZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/vxvv8DehmuFAayV2OH/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExbGZ4M205NGoycnE2M2dmYnI0ZDdoMDJ6ZnZyOTBzeGVqOWNpdXMzayZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3oEjI5VtIhHvK37WYo/giphy.gif',
]
