import classNames from 'classnames'

export default function MenuItem({
    children,
    activeTab,
    name,
    setActiveTab,
    testId,
    isFull = false,
}) {
    return (
        <li
            className={classNames(
                activeTab === name && 'rounded-md bg-light-secondary',
                isFull && 'w-full',
                'px-4 py-2 cursor-pointer'
            )}
            onClick={(e) => setActiveTab(name)}
            data-testid={testId}>
            {children}
        </li>
    )
}
