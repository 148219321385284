import classNames from 'classnames'

import Tooltip from '../Tooltip/Tooltip'

import checkmark from '../../Assets/checkmark.svg'
import checkMarkDark from '../../Assets/checkmark-dark.svg'
import { darkCheckmarkThemes } from '../../Data/const'

import { useTheme } from '../../Context/theme'

import './Step.css'

export default function Step({ children, help, name, number, isCompleted }) {
    const { theme } = useTheme()
    return (
        <div
            className="flex items-center w-full py-0.5 px-4 my-4 bg-background rounded-md"
            data-testid="write-step">
            <p
                className={classNames(
                    isCompleted
                        ? ' justify-center animate-[turn_400ms_ease-in-out]'
                        : 'justify-center text-secondary-text',
                    'mx-3 rounded-full bg-secondary step-number h-8 w-8 flex items-center'
                )}>
                {isCompleted ? (
                    <img
                        src={
                            darkCheckmarkThemes.includes(theme)
                                ? checkMarkDark
                                : checkmark
                        }
                        alt="check mark"
                        data-testid={`step-checkmark-${number}`}
                    />
                ) : (
                    number
                )}
            </p>
            <h2 className="mx-4">{name}</h2>
            {help ? <Tooltip>{children}</Tooltip> : null}
        </div>
    )
}
