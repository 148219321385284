import React from 'react'

export default function LandingFeatures({ image, alt, children }) {
    return (
        <div className="flex items-start mx-4 my-8 w-full sm:w-1/4">
            <img
                src={image}
                alt={alt}
                className="mr-4"
                data-testid="feature-image"
            />
            <p>{children}</p>
        </div>
    )
}
