import { Routes, Route } from 'react-router-dom'

import Write from './Pages/App/Write/Write'
import Stats from './Pages/App/Stats/Stats'
import Tracker from './Pages/App/Tracker/Tracker'
import Login from './Pages/Auth/Login/Login'
import Signup from './Pages/Auth/Signup/Signup'
import Landing from './Pages/App/Landing/Landing'
import Confirmation from './Pages/App/Confirmation/Confirmation'
import Characters from './Pages/App/Characters/Characters'
import Account from './Pages/App/Account/Account'
import PasswordRequest from './Pages/Auth/PasswordRequest/PasswordRequest'
import ResetPassword from './Pages/Auth/ResetPassword/ResetPassword'
import Help from './Pages/App/Help/Help'
import News from './Pages/App/News/News'
import NotFound from './Pages/App/NotFound/NotFound'

export default function AppRoutes({ setModal, modal }) {
    return (
        <Routes>
            <Route
                path="/"
                element={<Write setModal={setModal} modal={modal} />}
            />
            <Route exact path="/accueil" element={<Landing />} />
            <Route exact path="/statistiques" element={<Stats />} />
            <Route
                path="/rps"
                element={<Tracker setModal={setModal} modal={modal} />}
            />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/presentation" element={<Landing />} />
            <Route exact path="/confirmation" element={<Confirmation />} />
            <Route exact path="/personnages" element={<Characters />} />
            <Route exact path="/account" element={<Account />} />
            <Route
                exact
                path="/forgottenpassword"
                element={<PasswordRequest />}
            />
            <Route exact path="/resetpassword" element={<ResetPassword />} />
            <Route exact path="/aide" element={<Help />} />
            <Route exact path="/news" element={<News />} />
            <Route exact path="*" element={<NotFound />} />
        </Routes>
    )
}
