import React from 'react'
import classNames from 'classnames'

export default function Spinner({ centered, margin }) {
    return (
        <div
            className={classNames(
                margin ? 'my-8' : null,
                centered ? 'flex justify-center' : null
            )}
            data-testid="spinner">
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}
