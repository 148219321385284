import { createContext, useContext, useEffect, useState } from 'react'

import { getUser } from '../utils/utils'

const AuthContext = createContext()

export function AuthWrapper({ children }) {
    const [user, setUser] = useState()
    const [userIsLoading, setUserIsLoading] = useState(true)

    const fetchUser = async () => {
        setUserIsLoading(true)
        try {
            const current = await getUser()
            setUser(current.data)
            setUserIsLoading(false)
        } catch (_) {
            setUser(null)
            setUserIsLoading(false)
        } finally {
            setUserIsLoading(false)
        }
    }
    const updatePicture = (picture) => {
        setUser({
            ...user,
            picture: picture,
        })
    }
    const signout = () => {
        setUser({})
    }
    useEffect(() => {
        fetchUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <AuthContext.Provider
            value={{ user, fetchUser, userIsLoading, updatePicture, signout }}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    return useContext(AuthContext)
}
