import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

import Writing from '../../../Assets/writing.svg'
import Save from '../../../Assets/save.svg'
import Stats from '../../../Assets/stats.svg'
import Track from '../../../Assets/track.svg'
import Design from '../../../Assets/design.svg'
import Theme from '../../../Assets/theme.svg'
import HeroIllu from '../../../Assets/hero-img.svg'

import { getGlobalStats } from '../../../utils/utils'

import LandingStats from '../../../Components/LandingStats/LandingStats'
import LandingFeatures from '../../../Components/LandingFeatures/LandingFeatures'

export default function Landing() {
    const [userCount, setUserCount] = useState(0)
    const [rpCount, setRpCount] = useState(0)
    const [wordCount, setWordCount] = useState(0)
    const [statsLoaded, setStatsLoaded] = useState(false)
    const [statsError, setStatsError] = useState(false)

    const fetchGlobalStats = async () => {
        setStatsLoaded(false)
        try {
            const { data } = await getGlobalStats()
            setUserCount(data.userCount)
            setRpCount(data.totalRPs)
            setWordCount(data.totalWordCount)
            setStatsError(false)
        } catch (_) {
            setStatsError(true)
        } finally {
            setStatsLoaded(true)
        }
    }

    useEffect(() => {
        fetchGlobalStats()
    }, [])

    return (
        <div>
            <section className="flex flex-col my-16 items-center sm:flex-row">
                <div className="sm:mr-16">
                    <h1>Bienvenue sur Airpéger 👋</h1>
                    <p>
                        Airpéger est un outil qui vous permet de gérer vos RPs
                        plus facilement. Rejoignez la communauté d'amoureux du
                        RP !
                    </p>
                    <p>
                        L'outil est <strong>gratuit</strong>, et il le restera !
                    </p>
                    <button className="w-48 py-4 bg-secondary text-secondary-text rounded-md my-8">
                        <Link to="/signup">S'inscrire</Link>
                    </button>
                </div>
                <img
                    src={HeroIllu}
                    alt="illustration airpéger"
                    className="w-full sm:w-1/2"
                />
            </section>
            <section className="my-16 flex flex-col items-center">
                <h2>Le fonctionnement</h2>
                <div className="flex flex-wrap w-full justify-between">
                    <LandingFeatures image={Writing} alt="écriture">
                        Écrivez vos RPs directement sur la plateforme grâce à
                        notre éditeur de texte intégré.
                    </LandingFeatures>
                    <LandingFeatures image={Save} alt="sauvegarder">
                        Ne perdez plus vos brouillons ! Vos brouillons sont
                        enregistrés automatiquement sur la plateforme.
                    </LandingFeatures>
                    <LandingFeatures image={Design} alt="design">
                        Choisissez un design pour votre RP parmi plusieurs
                        templates. La plateforme vous fournira le code de votre
                        RP tout prêt !
                    </LandingFeatures>
                    <LandingFeatures image={Track} alt="suivre ses rps">
                        Trackez vos RPs automatiquement pour savoir où vous êtes
                        à jour et sur quel RP on attend une réponse de votre
                        part.
                    </LandingFeatures>
                    <LandingFeatures image={Stats} alt="statistiques">
                        Suivez vos statistiques de RP. La plateforme vous
                        permettra de voir vos statistiques par personnage et au
                        global.
                    </LandingFeatures>
                    <LandingFeatures image={Theme} alt="thème">
                        Personnalisez la plateforme aux couleurs qui vous
                        conviennent, et choisissez entre light et dark mode.
                    </LandingFeatures>
                </div>
            </section>
            <section className="my-16 flex flex-col items-center">
                <h2>La communauté</h2>
                <div className="sm:flex mt-8">
                    <LandingStats
                        statsLoaded={statsLoaded}
                        statsError={statsError}
                        data={userCount}>
                        Personnes utilisent Airpéger
                    </LandingStats>
                    <LandingStats
                        statsLoaded={statsLoaded}
                        statsError={statsError}
                        data={wordCount}>
                        Mots écrits sur la plateforme
                    </LandingStats>
                    <LandingStats
                        statsLoaded={statsLoaded}
                        statsError={statsError}
                        data={rpCount}>
                        RPs publiés avec airpéger
                    </LandingStats>
                </div>
            </section>
        </div>
    )
}
