import { useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppRoutes from './AppRoutes'
import Header from './Components/Navigation/Header/Header'
import Footer from './Components/Navigation/Footer/Footer'
import { ScrollToTop } from './Components/ScrollToTop/ScrollToTop'
import { AuthWrapper } from './Context/auth'
import { ThemeWrapper } from './Context/theme'

function App() {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    return (
        <BrowserRouter>
            <AuthWrapper>
                <ThemeWrapper>
                    <ScrollToTop />
                    <div className="relative">
                        <Header />
                        <main className="bg-main-background text-main w-full max-w-full sm:px-8 px-4 pt-8 pb-16">
                            <AppRoutes
                                setModal={setModalIsOpen}
                                modal={modalIsOpen}
                            />
                        </main>
                        <Footer />
                    </div>
                </ThemeWrapper>
            </AuthWrapper>
        </BrowserRouter>
    )
}

export default App
