import React from 'react'
import { themeMainColor } from '../../Data/const'
import classNames from 'classnames'

export default function ThemeSelector({ setChosenTheme, value, selected }) {
    return (
        <div
            className={classNames(
                selected === value
                    ? `border-secondary ${themeMainColor[value]} border-2`
                    : 'border border-secondary grayscale',
                'border-solid rounded-md w-fit px-3 py-1 mr-4 my-2 cursor-pointer'
            )}
            onClick={(e) => {
                setChosenTheme(e.target.textContent.toLowerCase())
            }}>
            {value}
        </div>
    )
}
