import Tooltip from '../Tooltip/Tooltip'

export default function RPInput({ func, placeholder, help, children }) {
    const handleInput = (e) => {
        func(e.target.value)
    }
    return (
        <div className="flex items-center mb-4">
            <input
                type="text"
                placeholder={placeholder}
                onChange={handleInput}
                className="px-4 py-2 mr-4 w-80 rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
            />
            {help && <Tooltip>{children}</Tooltip>}
        </div>
    )
}
