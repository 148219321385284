export const mainColors =
    'bg-off-white text-off-black dark:bg-off-black dark:text-off-white mint:text-earth-green mint:bg-bg-off-white'

export const disabledInputsColors =
    'bg-disabled-gray dark:bg-disabled-off-black'

export const lightThemes = ['light', 'mint', 'fall']
export const darkCheckmarkThemes = ['orchid', 'midnight', 'forest']

export const themeMainColor = {
    light: 'bg-light-main-background text-light-main',
    dark: 'bg-dark-main-background text-dark-main',
    mint: 'bg-mint-main-background text-mint-main',
    fall: 'bg-fall-main-background text-fall-main',
    halloween: 'bg-halloween-main-background text-halloween-main',
    orchid: 'bg-orchid-main-background text-orchid-main',
    midnight: 'bg-midnight-main-background text-midnight-main',
    forest: 'bg-forest-main-background text-forest-main',
}

export const themes = [
    'light',
    'dark',
    'mint',
    'fall',
    'halloween',
    'orchid',
    'midnight',
    'forest',
]
