export default function ButtonSpinner() {
    return (
        <div
            className="flex justify-center items-center"
            data-testid="spinner-button">
            <div className="lds-ring-button">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}
