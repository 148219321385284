import Step from '../../../Components/Step/Step'
import WordCount from '../../../Components/WordCount/WordCount'
import DesignCard from '../../../Components/DesignCard/DesignCard'
import PrimaryButton from '../../../Components/Buttons/PrimaryButton'
import SecondaryButton from '../../../Components/Buttons/SecondaryButton'
import RPInput from '../../../Components/RPInput/RPInput'
import Confirmation from '../../../Components/Confirmation/Confirmation'
import HelpItem from '../../../Components/HelpItem/HelpItem'

import arrowDown from '../../../Assets/Arrows/down.svg'
import arrowDownDark from '../../../Assets/Arrows/down-dark.svg'

import { designs } from '../../../Data/designs'

import { lightThemes } from '../../../Data/const'

import {
    getCharacters,
    updateDraft,
    getDraft,
    postRP,
    removeSpaces,
} from '../../../utils/utils'

import { useAuth } from '../../../Context/auth'
import { useTheme } from '../../../Context/theme'

import { Link, useNavigate } from 'react-router-dom'
import { useState, useRef, useEffect } from 'react'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { modules } from '../../../Tools/quillConfig'

import './Write.css'

export default function Write({ setModal, modal }) {
    const [value, setValue] = useState('')
    const [characterCount, setCharacterCount] = useState(0)
    const [wordCount, setWordCount] = useState(0)
    const [selectedCharacter, setSelectedCharacter] = useState('')
    const [selectedDesign, setSelectedDesign] = useState('')
    const [charName, setCharName] = useState('')
    const [charNameTwo, setCharNameTwo] = useState('')
    const [imageOne, setImageOne] = useState('')
    const [imageTwo, setImageTwo] = useState('')
    const [imageThree, setImageThree] = useState('')
    const [rpName, setRpName] = useState('')
    const [isCopied, setIsCopied] = useState(false)
    const [characters, setCharacters] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [showModalError, setShowModalError] = useState(false)
    const [postIsLoading, setPostIsLoading] = useState(false)

    const { user, userIsLoading } = useAuth()
    const { theme } = useTheme()

    const reactQuillRef = useRef()
    const navigate = useNavigate()

    const fetCharacters = async () => {
        try {
            const { data } = await getCharacters()
            setCharacters(data)
        } catch (_) {
            setCharacters([])
        }
    }

    const fetchDraft = async () => {
        const { data } = await getDraft()
        setValue(data)
    }

    const saveDraft = async (value) => {
        await updateDraft(value)
    }

    useEffect(() => {
        if (!userIsLoading && user) {
            fetchDraft()
            setIsLoading(true)
            fetCharacters()
            setIsLoading(false)
        }
        if (!userIsLoading && !user) {
            navigate('/accueil', { replace: true })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, userIsLoading])

    useEffect(() => {
        const timer = setTimeout(() => {
            saveDraft(value)
        }, 30000)
        return () => clearTimeout(timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wordCount])

    const updateText = (value) => {
        setValue(value)
        checkCharacterCount()
    }

    const handleCharacterChoice = (e) => {
        setSelectedCharacter(e.target.value)
    }

    const checkCharacterCount = () => {
        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor
        const textLength = unprivilegedEditor.getLength() - 1
        setCharacterCount(textLength)
        const textCount = unprivilegedEditor.getText().split(' ').length
        setWordCount(textCount)
    }

    const copyRP = () => {
        let codeStart = ''
        let codeEnd = ''

        switch (selectedDesign) {
            case 'Basic':
                codeStart = `<center><br/><div style="margin-top: 10px; margin-bottom: 20px; border-bottom: 1px dashed #333333; width: 40%; padding-bottom: 5px;"><p style="font-size: 1.3em;">${rpName}</p><p style="margin-bottom: 10px;">@"${charName}"</p></div><div style="text-align: justify; width: 450px;">`
                codeEnd = `</div></center>`
                break
            case 'Framed':
                codeStart = `<center><div style="margin-top: 10px; border: 10px solid #D5E8F7; max-width: 500px; padding-bottom: 20px;"><img src="${imageOne}" alt="image rp" style="width: 100%; object-fit: cover;"/><p style="font-size: 1.3em; margin-bottom: 20px;">${rpName}</p><div style="text-align: justify; width: 80%;">`
                codeEnd = `</div></div></center>`
                break
            case 'Float':
                codeStart = `<center><div style="margin-top: 10px; max-width: 500px; padding-bottom: 20px;"><p style="font-size: 1.3em;">${rpName}</p><p style="margin-bottom: 20px;">@"${charName}"</p><div style="text-align: justify;"><div style="float: left; display: flex; flex-direction: column;"><img src="${imageOne}" alt="image rp" style="width: 70px; height: 70px; object-fit: cover; margin: 5px 15px 15px 0;"/><img src="${imageTwo}" alt="image rp" style="width: 70px; height: 70px; object-fit: cover; margin: 0 15px 15px 0;"/></div>`
                codeEnd = `</div></div></center>`
                break
            case 'Clean':
                codeStart = `<center><div style="display: flex; justify-content: center;"><img src="${imageOne}" alt="image rp" style="width: 120px; height: 120px; object-fit: cover; margin-right: 10px; padding: 5px; border: 1px solid #DADADA;"/><img src="${imageTwo}" alt="image rp" style="width: 120px; height: 120px; object-fit: cover; margin-left: 10px; padding: 5px; border: 1px solid #DADADA;"/></div><p style="margin-top: 20px; margin-bottom: -5px;">${rpName}</p><p style="margin-bottom: 20px;">@"${charName}"</p><div style="text-align: justify; max-width: 450px;">`
                codeEnd = `</div></center>`
                break
            case 'Saturn':
                codeStart = `<center><div style="display: flex; text-align: justify; justify-content: center;"><div style="display: flex; flex-direction: column; margin-right: 15px; margin-top: 1.5rem;"><img src="${imageOne}" style="width: 60px;height: 60px;border-radius:100%;border: 1px solid #dbc5ab;object-fit:cover; margin: 5px 0px; padding: 2px;"><img src="${imageTwo}" style="width: 60px;height: 60px;border-radius:100%;border: 1px solid #dbc5ab;object-fit:cover; margin: 5px 0px; padding: 2px;"></div><div style="width: 350px; padding: 5px; margin-top: 0px; padding-left: 20px; border-left: 1px solid #dbc5ab" ><p style="text-transform: uppercase;text-align: right; font-weight: bold;">${rpName}</p><p style="text-align: right; margin-top: -10px; margin-bottom: 30px;">@"${charName}"</p>`
                codeEnd = `</div></div></center>`
                break
            case 'Float left':
                codeStart = `<link href="https://airpeger.fr/themes/float.css" rel="stylesheet" type="text/css" /><center><div class="airpeger_float"> <div class="header"> <p class="title">${rpName}</p> <p class="name">@"${charName}"</p> </div> <div class="container"> <img src="${imageOne}"/>`
                codeEnd = `</div></div></center>`
                break
            case 'Float right':
                codeStart = `<link href="https://airpeger.fr/themes/float2.css" rel="stylesheet" type="text/css" /><center><div class="airpeger_float2"> <div class="header"> <p class="title">${rpName}</p> <p class="name">@"${charName}"</p> </div> <div class="container"> <img src="${imageOne}" />`
                codeEnd = `</div></div></center>`
                break
            case 'Jupiter':
                codeStart = `<link href="https://fonts.googleapis.com/css2?family=New+Amsterdam&display=swap" rel="stylesheet"><center><p style="margin-bottom:0;font-size:.7rem;font-style:italic">@"${charName}"</p><p style="margin-top:5px;margin-bottom:20px;font-family:'New Amsterdam',sans-serif;font-size:14px">${rpName}</p><div style="display:flex;justify-content:center"><img src="${imageOne}" alt="image rp" style="width:170px;height:90px;object-fit:cover;margin-right:10px;padding:5px;border:1px solid #dadada"><img src="${imageTwo}" alt="image rp" style="width:170px;height:90px;object-fit:cover;margin-left:10px;padding:5px;border:1px solid #dadada"></div><div style="text-align:justify;width:384px">`
                codeEnd = `</div></center>`
                break
            case 'Diamond':
                codeStart = `<link href="https://fonts.googleapis.com/css2?family=New+Amsterdam&display=swap" rel="stylesheet">
                <center><img src="${imageOne}" alt="image rp" style="width: 100px; height: 120px; clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%); object-fit: cover; margin-bottom: 20px;"/><p style="margin-bottom: 0px; font-size: 0.7rem; font-style: italic;">@"${charName}"</p><p style="margin-top: 5px; margin-bottom: 20px; font-family: 'New Amsterdam', sans-serif; font-size: 14px;">${rpName}</p><div style="text-align: justify; width: 384px; border-top: 3px solid #dbc5ab;">`
                codeEnd = `</div></center>`
                break
            case 'Ring':
                codeStart = `<link href="https://fonts.googleapis.com/css2?family=New+Amsterdam&display=swap" rel="stylesheet">
                <center><p style="margin-bottom: 0px; font-size: 0.7rem; font-style: italic;">@"${charName}"</p><p style="margin-top: 5px; margin-bottom: 20px; font-family: 'New Amsterdam', sans-serif; font-size: 14px; margin-bottom: 20px;">${rpName}</p><img src="${imageOne}" alt="image rp" style="width: 80px; height: 80px; border-radius: 100%; border: 1px solid #dbc5ab;object-fit: cover; margin-bottom: -45px;"/><div style="text-align: justify; border: 1px solid #dbc5ab; width: 400px; padding: 60px 20px 10px 20px;">`
                codeEnd = `</div></center>`
                break
            case 'Dublin':
                codeStart = `<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;800&display=swap" rel="stylesheet"><center><div style="width: 384px;text-align: justify;margin-bottom: 20px;"><div style="margin-bottom: 20px;margin-right: 30px; text-align: right;"><p style="font-family: 'Poppins', sans-serif; font-weight: 800;text-transform: lowercase;font-size: 1.1rem; margin-bottom: 5px;">${rpName}</p> <p style="margin: 0;font-size: 0.7rem;">@"${charName}"</p></div><div style="display:flex;justify-content:center"><img src="${imageOne}" alt="image rp" style="width:170px;height:90px;object-fit:cover;margin-right:10px;padding:5px;border:1px solid #dadada"><img src="${imageTwo}" alt="image rp" style="width:170px;height:90px;object-fit:cover;margin-left:10px;padding:5px;border:1px solid #dadada"></div><div style="text-align:justify;width:384px">`
                codeEnd = `</div></div></center>`
                break
            case 'Trapeze':
                codeStart = `<center><p style="margin-top: 20px; margin-bottom: -10px;font-weight: bold;text-transform:lowercase;">${rpName}</p><p style="margin-bottom: 10px;">@"${charName}" | @"${charNameTwo}"</p><div style="display: flex; justify-content: center;margin-bottom:30px;"><img src="${imageOne}" alt="image rp" style="width: 95px; height: 95px; margin-right: -20px;object-fit: cover; clip-path: polygon(50% 100%, 100% 0, 0 0);"/><img src="${imageTwo}" alt="image rp" style="width: 95px; height: 95px; margin: 0 -20px;object-fit: cover; clip-path: polygon(50% 0%, 0% 100%, 100% 100%);"/><img src="${imageThree}" alt="image rp" style="width: 95px; height: 95px; margin-left: -20px;object-fit: cover; clip-path: polygon(50% 100%, 100% 0, 0 0);"/></div><div style="text-align: justify; max-width: 450px; border: 1px solid #DADADA; padding: 5px 10px;margin-bottom:30px;">`
                codeEnd = `</div></center>`
                break
            case 'Moon':
                codeStart = `<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet"><center><p style="text-transform: lowercase;font-size:1rem;font-family:'Poppins',sans-serif;font-weight: 600;margin-bottom: 5px;">${rpName}</p><p style="margin-bottom: 20px;margin-top: 0;">@"${charName}"</p><div style="text-align: justify; max-width: 436px;display:flex;margin-bottom: 30px;"><img src="${imageOne}" alt="image rp" style="width: 120px; height: 120px; object-fit: cover; padding: 5px; border: 1px solid #DADADA;width: 50px;height: 100px;border-radius: 100px 0 0 100px;"/><div style="padding: 10px 15px; margin: 0 10px;">`
                codeEnd = `</div><img src="${imageTwo}" alt="image rp" style="width: 120px; height: 120px; object-fit: cover; padding: 5px; border: 1px solid #DADADA;width: 50px;height: 100px;border-radius: 0 100px 100px 0;align-self:flex-end;"/></div></center>`
                break
            case 'Conversation':
                codeStart = `<link href="https://fonts.googleapis.com/css2?family=New+Amsterdam&display=swap" rel="stylesheet">
                <center><div style="display: flex; justify-content:center;margin-top:50px;"><img src="${imageOne}" alt="image rp" style="width: 100px; height: 100px;clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%); object-fit: cover; margin-top: -25px;"/><div style="width: 380px;"><p style=" font-family: 'New Amsterdam', sans-serif; font-size: 14px;margin-bottom: 0;">${rpName}</p><p style="margin-bottom: 20px;margin-top:5px; font-size: 0.7rem; font-style: italic;">@"${charName}"</p><div style="display:flex;justify-content:center;"><div style="text-align: justify; border: 1px solid #DADADA;padding: 10px 15px;">`
                codeEnd = `</div></div></div></div></center>`
                break
            case 'Clean2':
                codeStart = `<center><div style="display: flex; justify-content: center;"><img src="${imageOne}" alt="image rp" style="width: 120px; height: 120px; object-fit: cover; margin-right: 10px; padding: 5px; border: 1px solid #DADADA;"/><img src="${imageTwo}" alt="image rp" style="width: 120px; height: 120px; object-fit: cover; margin:0 10px; padding: 5px; border: 1px solid #DADADA;"/><img src="${imageThree}" alt="image rp" style="width: 120px; height: 120px; object-fit: cover; margin-left: 10px; padding: 5px; border: 1px solid #DADADA;"/></div><p style="margin-top: 20px; margin-bottom: -5px;">${rpName}</p><p style="margin-bottom: 20px;">@"${charName}" | @"${charNameTwo}"</p><div style="text-align: justify; max-width: 436px;">`
                codeEnd = `</div></center>`
                break
            case 'Chat':
                codeStart = `<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet"><center><div style="display: flex; text-align: justify; justify-content: center;"><div style="width: 350px; padding: 5px; margin-top: 0px; padding-right: 20px; border-right: 1px solid #dadada;"><div style="padding: 5px 15px;border: 1px dashed #dadada;text-align:right;"><p style="text-transform: lowercase;font-family:'Poppins',sans-serif;font-weight: 600;";text-align: right;">${rpName}</p><p style="text-align: right; margin-top: -10px;">@"${charName}"</p></div>`
                codeEnd = `</div><div style="display: flex; flex-direction: column; margin-left: 15px;"><img src="${imageOne}" style="width: 60px;height: 60px;border: 1px solid #dbc5ab;object-fit:cover; margin: 5px 0px; padding: 2px;"><img src="${imageTwo}" style="width: 60px;height: 60px;border: 1px solid #dbc5ab;object-fit:cover; margin: 5px 0px; padding: 2px;"></div></div></center>`
                break
            case 'Battle':
                codeStart = `<link href="https://fonts.googleapis.com/css2?family=New+Amsterdam&display=swap" rel="stylesheet">
                <center><p style="margin-top: 5px; margin-bottom: 0; font-family: 'New Amsterdam', sans-serif; font-size: 14px;">${rpName}</p><p style="margin-bottom: 20px; margin-top: 0px;font-size: 0.7rem;">@"${charName}"</p><img src="${imageOne}" alt="image rp" style="width: 120px; height: 80px;object-fit: cover; margin-bottom: -45px;margin-right: -15px;clip-path: polygon(0 0, 100% 0%, 60% 100%, 0% 100%);"/><img src="${imageTwo}" alt="image rp" style="width: 120px; height: 80px;object-fit: cover; margin-bottom: -45px;margin-left: -15px;clip-path: polygon(40% 0, 100% 0, 100% 100%, 0% 100%);;"/><div style="text-align: justify; border: 1px solid #DADADA; width: 400px; padding: 60px 20px 10px 20px; margin-bottom: 30px;">`
                codeEnd = `</div></center>`
                break
            case 'Letter':
                codeStart = `<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet"><center><div style="display: flex; text-align: justify; justify-content: space-between; align-items:center;width: 450px;margin-top: 20px;"><div><img src="${imageOne}" style="width: 60px;height: 60px;border: 1px solid #dadada;object-fit:cover; border-radius: 100%; padding: 2px; margin-right: 10px;"><img src="${imageTwo}" style="width: 60px;height: 60px;border: 1px solid #dadada;object-fit:cover; border-radius: 100%; padding: 2px;"></div><div style="text-align: right;text-transform: lowercase;font-family:'Poppins',sans-serif;flex:2; margin-left: 20px;"><p style="font-weight: 600;margin: 0;">${rpName}</p><p style="font-size: 0.7rem; margin:0;">@"${charName}"</p></div></div><div style="text-align: justify; margin-top: 20px;max-width: 450px; border: 1px solid #DADADA; padding: 10px 15px;margin-bottom:30px;">`
                codeEnd = `</div></center>`
                break
            case 'Dash':
                codeStart = `<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet"><center><div style="width: 400px; padding: 5px; margin: 20px 0;"><div><img src="${imageOne}" style="width: 190px;height: 100px;border: 1px solid #dadada;object-fit:cover; margin: 5px 0px; padding: 2px; margin-right:8px;"><img src="${imageTwo}" style="width: 190px;height: 100px;border: 1px solid #dadada;object-fit:cover; margin: 5px 0px; padding: 2px;"></div><div style="padding: 0px 15px;border: 1px dashed #dadada;text-align:justify; margin-top: 5px; text-align:center;"><p style="text-transform: lowercase;font-family:'Poppins',sans-serif;font-weight: 600;">[b]${rpName}[/b]</p><p style="margin-top: -15px;">@"${charName}"</p></div><div style="text-align: justify;">`
                codeEnd = `</div></div></center>`
                break
            case 'Lizzie':
                codeStart = `<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet"><center><div style="display: flex; text-align: justify; justify-content: space-between; align-items:center;width: 400px; margin: 20px 0 30px 0;"><div><img src="${imageOne}" style="width: 60px;height: 60px;border: 1px solid #dadada;object-fit:cover; padding: 2px; margin-right: 10px;"><img src="${imageTwo}" style="width: 60px;height: 60px;border: 1px solid #dadada;object-fit:cover; padding: 2px;"></div><div style="text-align: right;text-transform: lowercase;font-family:'Poppins',sans-serif;flex:2; margin-left: 20px;"><p style="font-weight: 600;margin: 0;">${rpName}</p><p style="font-size: 0.7rem; margin:0;">@"${charName}"</p></div></div><div style="text-align: justify; margin-top: 20px;max-width: 400px;margin-bottom:30px;">`
                codeEnd = `</div></center>`
                break
            case 'Royal':
                codeStart = `<link href="https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap" rel="stylesheet"><center><div style="width: 450px; padding: 5px; margin: 20px 0;"><div><img src="${imageOne}" style="width: 190px;height: 190px;border: 1px solid #dadada;padding: 4px;object-fit:cover; margin: 5px 0px; margin-right:8px;"><img src="${imageTwo}" style="width: 190px;height: 190px;padding: 4px;border: 1px solid #dadada;object-fit:cover; margin: 5px 0px;"></div><div style="padding: 0px 15px;border: 1px solid #dadada;text-align:justify; margin-top: 5px; text-align:center;"><p style="text-transform: uppercase; font-size: 1rem;font-family:'Playfair Display',serif;font-weight: 600;letter-spacing: .1rem;">[b]${rpName}[/b]</p><p style="margin-top: -15px;">@"${charName}"</p></div><div style="text-align: justify; width: 410px;">`
                codeEnd = `</div></div></center>`
                break
            default:
                // eslint-disable-next-line no-unused-vars
                codeStart = ''
                // eslint-disable-next-line no-unused-vars
                codeEnd = ''
        }
        navigator.clipboard.writeText(codeStart + removeSpaces(value) + codeEnd)
        setIsCopied(true)
    }

    const validateRP = () => {
        setModal(true)
    }

    const closeModal = () => {
        setModal(false)
    }

    const publishRP = async () => {
        setPostIsLoading(true)
        try {
            await postRP(selectedCharacter, wordCount, characterCount)
            setModal(false)
            navigate(
                `/confirmation?character=${selectedCharacter}&wordcount=${wordCount}`,
                { replace: true }
            )
        } catch (_) {
            setShowModalError(true)
        } finally {
            setPostIsLoading(false)
        }
    }

    const designNoImages = designs.filter(
        (item) => !item.hasImage1 && !item.hasImage2 && !item.hasImage3
    )
    const designOneImage = designs.filter(
        (item) => item.hasImage1 && !item.hasImage2 && !item.hasImage3
    )
    const designTwoImages = designs.filter(
        (item) => item.hasImage1 && item.hasImage2 && !item.hasImage3
    )
    const designThreeImages = designs.filter(
        (item) => item.hasImage1 && item.hasImage2 && item.hasImage3
    )

    return (
        <div>
            <Step
                help={true}
                name={'Choisir un personnage'}
                number={1}
                isCompleted={selectedCharacter}>
                <p>
                    Les statistiques de votre RP seront associées à ce
                    personnage. Pensez à bien choisir le personnage avec lequel
                    vous écrivez ce rp 🙂
                </p>
            </Step>
            <div className="sm:flex items-center">
                <div className="relative sm:mb-0 mb-4">
                    <select
                        className="py-4 pl-4 pr-12 w-80 bg-background rounded-md border border-solid border-border-color appearance-none"
                        onChange={handleCharacterChoice}
                        data-testid="character-select"
                        disabled={isLoading}>
                        <option value="">Nom du personnage</option>
                        {characters.map((item, index) => (
                            <option key={`${item}-${index}`} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                    <img
                        src={
                            lightThemes.includes(theme)
                                ? arrowDown
                                : arrowDownDark
                        }
                        alt="fleche bas"
                        className="absolute left-72 top-6"
                    />
                </div>
                <Link to="/personnages" className="sm:ml-8 text-secondary">
                    Gérer mes personnages
                </Link>
            </div>

            <Step name={'Rédiger le RP'} number={2} isCompleted={value}></Step>
            <div className="sm:flex">
                <ReactQuill
                    className="quill-custom sm:w-2/3 w-full bg-main-background"
                    value={value}
                    onChange={updateText}
                    modules={modules}
                    theme="snow"
                    ref={reactQuillRef}
                    data-testid="quill"
                />
                <div>
                    <WordCount type="characters" count={characterCount} />
                    <WordCount type="words" count={wordCount} />
                </div>
            </div>
            <Step
                help={true}
                name={'Choisir un design'}
                number={3}
                isCompleted={selectedDesign}>
                <p>
                    Si vous choisissez un design, il sera automatiquement
                    intégré à votre RP quand vous cliquerez sur "Copier le RP"
                    en bas de page. Vous pourrez ensuite coller votre RP
                    directement avec le design choisi 🙂
                </p>
                <p>
                    Vous pouvez également ne choisir aucun design. Dans ce cas,
                    seul le texte de votre RP sera copié.
                </p>
            </Step>
            <HelpItem title="Design sans images">
                <ul className="flex flex-wrap justify-center">
                    {designNoImages.map((item, index) => (
                        <DesignCard
                            key={`${item.title}-${index}`}
                            image={
                                lightThemes.includes(theme)
                                    ? item.image
                                    : item.imageDark
                            }
                            name={item.title}
                            isSelected={selectedDesign === item.title}
                            setSelectedDesign={setSelectedDesign}
                        />
                    ))}
                </ul>
            </HelpItem>
            <HelpItem title="Design avec 1 image">
                <ul className="flex flex-wrap justify-center">
                    {designOneImage.map((item, index) => (
                        <DesignCard
                            key={`${item.title}-${index}`}
                            image={
                                lightThemes.includes(theme)
                                    ? item.image
                                    : item.imageDark
                            }
                            name={item.title}
                            isSelected={selectedDesign === item.title}
                            setSelectedDesign={setSelectedDesign}
                        />
                    ))}
                </ul>
            </HelpItem>
            <HelpItem title="Design avec 2 images">
                <ul className="flex flex-wrap justify-center">
                    {designTwoImages.map((item, index) => (
                        <DesignCard
                            key={`${item.title}-${index}`}
                            image={
                                lightThemes.includes(theme)
                                    ? item.image
                                    : item.imageDark
                            }
                            name={item.title}
                            isSelected={selectedDesign === item.title}
                            setSelectedDesign={setSelectedDesign}
                        />
                    ))}
                </ul>
            </HelpItem>
            <HelpItem title="Design avec 3 images">
                <ul className="flex flex-wrap justify-center">
                    {designThreeImages.map((item, index) => (
                        <DesignCard
                            key={`${item.title}-${index}`}
                            image={
                                lightThemes.includes(theme)
                                    ? item.image
                                    : item.imageDark
                            }
                            name={item.title}
                            isSelected={selectedDesign === item.title}
                            setSelectedDesign={setSelectedDesign}
                        />
                    ))}
                </ul>
            </HelpItem>
            {selectedDesign && (
                <div>
                    {designs.filter((el) => el.title === selectedDesign).shift()
                        .hasTitreRP && (
                        <RPInput
                            placeholder="Titre de mon RP"
                            func={setRpName}
                            help={false}
                        />
                    )}
                    {designs.filter((el) => el.title === selectedDesign).shift()
                        .hasNomPerso && (
                        <RPInput
                            placeholder="Personnage à qui je réponds"
                            func={setCharName}
                            help={true}>
                            <p>
                                Ajoutez le pseudo du personnage à qui vous
                                répondez avec la même orthographe que sur votre
                                forum. Le design taggera automatiquement votre
                                partenaire de RP quand vous posterez votre
                                réponse 😉
                            </p>
                        </RPInput>
                    )}
                    {designs.filter((el) => el.title === selectedDesign).shift()
                        .hasNomPerso2 && (
                        <RPInput
                            placeholder="Deuxième personnage à qui je réponds"
                            func={setCharNameTwo}
                            help={false}></RPInput>
                    )}
                    {designs.filter((el) => el.title === selectedDesign).shift()
                        .hasImage1 && (
                        <RPInput
                            placeholder="URL de ma 1ère image"
                            func={setImageOne}
                            help={false}
                        />
                    )}
                    {designs.filter((el) => el.title === selectedDesign).shift()
                        .hasImage2 && (
                        <RPInput
                            placeholder="URL de ma 2ème image"
                            func={setImageTwo}
                            help={false}
                        />
                    )}
                    {designs.filter((el) => el.title === selectedDesign).shift()
                        .hasImage3 && (
                        <RPInput
                            placeholder="URL de ma 3ème image"
                            func={setImageThree}
                            help={false}
                        />
                    )}
                </div>
            )}
            <Step
                help={true}
                name={'Finaliser le RP'}
                number={4}
                isCompleted={isCopied}>
                <p>
                    Cliquez sur "Copier le RP" pour récupérer votre RP avec le
                    design choisi. Il sera prêt à être posté !
                </p>
                <p>
                    <strong>Attention</strong> : en cliquant sur "J'ai posté mon
                    RP", votre brouillon sera supprimé. Ne cliquez sur ce bouton
                    que quand vous aurez publié le RP !
                </p>
            </Step>
            <div className="flex flex-col sm:flex-row items-center justify-center my-4">
                <SecondaryButton clickFunc={copyRP}>
                    {isCopied ? 'RP copié' : 'Copier mon RP'}
                </SecondaryButton>
                <PrimaryButton clickFunc={validateRP}>
                    J'ai posté mon RP
                </PrimaryButton>
            </div>
            {modal && (
                <Confirmation
                    closeModal={closeModal}
                    publishRP={publishRP}
                    warning={
                        value &&
                        selectedCharacter &&
                        selectedCharacter &&
                        isCopied
                    }
                    error={showModalError}
                    postIsLoading={postIsLoading}
                    source="write"
                />
            )}
        </div>
    )
}
