import { useState } from 'react'
import classNames from 'classnames'

import arrowDown from '../../Assets/Arrows/down.svg'
import arrownDownDark from '../../Assets/Arrows/down-dark.svg'
import { lightThemes } from '../../Data/const'

import { useTheme } from '../../Context/theme'

export default function HelpItem({ title, children }) {
    const { theme } = useTheme()
    const [isShown, setIsShown] = useState(false)
    const handleClick = () => {
        setIsShown(!isShown)
    }
    return (
        <>
            <div
                onClick={handleClick}
                className="flex items-center justify-between bg-background text-main px-8 py-4 rounded-md cursor-pointer mb-4">
                <div className="w-full">{title}</div>
                <img
                    src={
                        lightThemes.includes(theme) ? arrowDown : arrownDownDark
                    }
                    alt="flèche"
                    className={classNames(
                        isShown ? 'rotate-180' : null,
                        'w-4 h-4 ease-in-out duration-300'
                    )}
                />
            </div>
            <div className={isShown ? 'px-8 py-4 mb-4 text-justify' : 'hidden'}>
                {children}
            </div>
        </>
    )
}
