import { lightThemes } from '../../Data/const'

import { useTheme } from '../../Context/theme'
import Trash from '../../Assets/trash-light.svg'
import TrashDark from '../../Assets/trash-dark.svg'

export default function CharacterItem({ character, removeCharacter }) {
    const { theme } = useTheme()
    const deleteCharacter = () => {
        removeCharacter(character)
    }
    return (
        <div className="bg-background text-main flex sm:flex-row flex-col sm:justify-between sm:items-center rounded-md px-8 py-4 my-4">
            <div className="flex justify-start items-center">
                <span>{character}</span>
            </div>
            <img
                className="cursor-pointer trash-icon w-6 h-6"
                onClick={deleteCharacter}
                src={lightThemes.includes(theme) ? Trash : TrashDark}
                alt="supprimer"
            />
        </div>
    )
}
