import NewsItem from '../../../Components/NewsItem/NewsItem'

export default function News() {
    return (
        <>
            <h1>Nouveautés</h1>
            <NewsItem header="18 Octobre 2024">
                <p>
                    Mise à jour du suivi des RPs pour ajouter deux
                    fonctionnalités :
                </p>
                <ul className="list-disc ml-8">
                    <li>
                        Pouvoir suivre le statut d'un rp manuellement : si
                        Airpéger ne parvient pas à détecter le statut de votre
                        RP de manière automatique, vous pouvez désormais choisir
                        de sélectionner son statut manuellement
                    </li>
                    <li>
                        Voir ses statistiques de RP sous forme de graphique (RPs
                        à jour vs pas à jour, proportion de RP par personnage,
                        ...)
                    </li>
                </ul>
                <p>
                    J'ai aussi profité de cette mise à jour pour ajouter un
                    design à 2 images, et pour corriger un problème d'afficher
                    du loader sur la page de suivi des RPs.
                </p>
                <p>
                    N'hésitez pas à me dire si vous souhaitez voir une nouvelle
                    fonctionnalité sur la plateforme, ou si vous avez un retour
                    ❤️
                </p>
            </NewsItem>
            <NewsItem header="13 Octobre 2024">
                <p>
                    Mise à jour de la page de suivi des RPs pour ajouter deux
                    nouvelles catégories :
                </p>
                <ul className="list-disc ml-8">
                    <li>Les RPs terminés</li>
                    <li>Les RPs à ouvrir</li>
                </ul>
                <p>
                    Vous pouvez donc désormais archiver un RP une fois qu'il est
                    terminé, et ajouter une note pour vous souvenir d'ouvrir vos
                    futurs RPs. J'espère que ces nouveaux onglets vous seront
                    utiles ❤️
                </p>
            </NewsItem>
            <NewsItem header="14 Septembre 2024">
                <p>
                    Petite mise à jour pour ranger les différents designs de RP
                    dans différentes catégories pour simplifier le choix :
                </p>
                <ul className="list-disc ml-8">
                    <li>Designs sans images</li>
                    <li>Design avec 1 image</li>
                    <li>Design avec 2 images</li>
                    <li>Design avec 3 images</li>
                </ul>
                <p>
                    Si vous souhaitez qu'un nouveau design soit ajouté,
                    n'hésitez pas à me contacter !
                </p>
            </NewsItem>
            <NewsItem header="31 Août 2024">
                <p>
                    Ajout de plusieurs nouvelles fonctionnalités sur la page
                    "RPs" :
                </p>
                <ul className="list-disc ml-8">
                    <li>
                        Possibilité de noter <strong>manuellement</strong> la
                        date de dernière réponse d'un RP. Vous pouvez modifier
                        vos RPs existants pour ajouter la date !
                    </li>
                    <li>
                        Tri des RPs par date de dernière réponse, par ordre
                        alphabétique, et par forum
                    </li>
                    <li>
                        Ajout du nombre de RPs dans votre liste, avec ou sans
                        les filtres !
                    </li>
                </ul>
                <p>
                    J'aurais aimé pouvoir récupérer la date de dernière réponse
                    à un RP de manière automatique, mais ce n'était pas
                    possible. Vous pouvez continuer à utiliser la plateforme
                    sans ajouter la date de dernière réponse, ou vous servir de
                    cette nouvelle fonctionnalité pour répondre à vos RPs dans
                    l'ordre ❤️
                </p>
            </NewsItem>
            <NewsItem header="14 Août 2024">
                <p>
                    Ajout de plusieurs designs pour vos rps avec notamment
                    l'ajout de plusieurs designs pour des RPs à 3 (donc avec 3
                    images, et 2 pseudos auxquels on répond).
                </p>
                <p>
                    Si vous utilisez souvent un design et que vous voulez
                    l'ajouter à la plateforme, n'hésitez pas à{' '}
                    <a href="mailto:airpeger@gmail.com">me contacter</a>, je
                    pourrai voir pour ajouter un thème similaire à la plateforme
                    ❤️
                </p>
            </NewsItem>
            <NewsItem header="20 Mars 2024">
                <ul className="list-disc ml-8">
                    <li>Ajout d'un filtre sur les personnages liés aux RPs</li>
                    <li>
                        Ajout de deux dark themes : midnight 🌑 et forest 🌳
                    </li>
                    <li>
                        Modification du lien "supprimer le personnage" pour
                        mettre une icône
                    </li>
                    <li>
                        Correction d'un bug avec l'affichage intempestif de la
                        modale de confirmation
                    </li>
                </ul>
                <p>
                    Comme promis, après avoir ajouté la fonctionnalité qui
                    permet de lier les RPs à des personnages, le filtre par
                    personnage est désormais disponible ❤️ On m'avait également
                    suggéré un filtre ou un tri par forum, mais le filtre par
                    personnage me paraissait plus adapté !
                </p>
            </NewsItem>
            <NewsItem header="27 Janvier 2024">
                <p>Mise à jour de la page de tracking des RPs :</p>
                <ul className="list-disc ml-8">
                    <li>Ajout d'un filtre sur le statut des RPs</li>
                    <li>Ajout du nom du RP</li>
                    <li>
                        Ajout du personnage lié au RP (pour permettre plus tard
                        un filtre là-dessus également)
                    </li>
                    <li>Ajout de la possibilité de modifier un RP suivi</li>
                </ul>
                <p>
                    Il s'agit en fait d'une première grosse modification
                    technique qui devrait ensuite permettre d'ajouter d'autres
                    filtres et tris sur la page de suivi des RPs 😊
                </p>
            </NewsItem>
            <NewsItem header="28 Septembre 2023">
                <p>Très légère mise à jour pour corriger quelques détails :</p>
                <ul className="list-disc ml-8">
                    <li>
                        Modification du menu sur mobile pour rendre la
                        navigation plus simple
                    </li>
                    <li>
                        Modification de l'affichage du chargement (dans le
                        bouton plutôt qu'au-dessus)
                    </li>
                    <li>
                        Correction de l'affichage de la page de confirmation sur
                        mobile
                    </li>
                    <li>
                        Correction de la couleur de la pastille "personnage
                        inactif" dans les statistiques
                    </li>
                    <li>
                        Correction de l'affichage du message d'information sur
                        la page des statistiques
                    </li>
                </ul>
            </NewsItem>
            <NewsItem header="22 Septembre 2023">
                <p>
                    Une petite mise à jour a été publiée avec quelques
                    améliorations :
                </p>
                <ul className="list-disc ml-8">
                    <li>
                        Ajout du thème <strong>orchid</strong> 💐, un nouveau
                        thème sombre
                    </li>
                    <li>
                        Correction d'un bug qui empêchait de supprimer un
                        personnage
                    </li>
                    <li>
                        Ajout de messages d'informations si le compte n'a pas de
                        personnage ou de RP à suivre
                    </li>
                    <li>
                        Création d'une page d'erreur si l'URL n'existe pas sur
                        le site
                    </li>
                    <li>
                        Nouveau design de la page de confirmation une fois un RP
                        posté
                    </li>
                    <li>Correction du design en version mobile</li>
                </ul>
            </NewsItem>
            <NewsItem header="16 Septembre 2023">
                <p>Le site a été mis à jour avec plusieurs nouveautés :</p>
                <ul className="list-disc ml-8">
                    <li>Un nouveau design</li>
                    <li>
                        Une validation des différentes étapes quand vous créez
                        un RP : si vous oubliez de renseigner une info, le site
                        vous le dira
                    </li>
                    <li>
                        Un meilleur affichage des stats : on n'affiche plus le
                        champ 'Aucun personnage' si tous vos RPs sont bien
                        attribués à un personnage
                    </li>
                    <li>
                        De nouveaux <b>thèmes</b>
                    </li>
                </ul>
                <p>
                    Cette mise à jour devrait être transparente, il s'agissait
                    surtout d'une refonte technique et j'en ai profité pour
                    apporter quelques améliorations ! 😊
                </p>
                <p className="my-4">
                    <b>À noter</b> : Suite à la mise à jour, vous devriez avoir
                    retrouvé le thème blanc / light par défaut. Vous pouvez{' '}
                    <b>remettre un autre thème (dont des thèmes dark)</b> en
                    cliquant sur votre avatar, depuis votre compte.
                </p>
                <p>
                    Comme d'habitude, si vous avez des questions / remarques,
                    n'hésitez pas à{' '}
                    <a href="mailto:airpeger@gmail.com">me contacter</a> !
                </p>
                <p>❤️</p>
            </NewsItem>
        </>
    )
}
