import { Link } from 'react-router-dom'

import PrimaryButton from '../../../Components/Buttons/PrimaryButton'

export default function NotFound() {
    return (
        <section className="flex flex-col items-center mt-8">
            <div className="mb-4">Oups, cette page n'existe pas !</div>
            <Link to="/">
                <PrimaryButton>Retourner à l'accueil</PrimaryButton>
            </Link>
        </section>
    )
}
