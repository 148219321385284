export default function FormSubmit({ value }) {
    return (
        <input
            action=""
            method="post"
            className="w-80 py-4 bg-secondary text-secondary-text rounded-md m-auto mt-8 cursor-pointer"
            type="submit"
            value={value}
        />
    )
}
