import { useState } from 'react'
import { Link } from 'react-router-dom'

import { editPassword } from '../../../utils/utils'
import InfoMessage from '../../../Components/InfoMessage/InfoMessage'
import ButtonSpinner from '../../../Components/ButtonSpinner/ButtonSpinner'
import PrimaryButton from '../../../Components/Buttons/PrimaryButton'

export default function ResetPassword() {
    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get('token')

    const [password, setPassword] = useState('')
    const [confPassword, setConfPassword] = useState('')
    const [typingError, setTypingError] = useState(false)
    const [showError, setShowError] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const modifyPassword = async (e) => {
        e.preventDefault()
        setShowError(false)
        setTypingError(false)
        if (password === confPassword) {
            setIsLoading(true)
            try {
                await editPassword(password, token)
                setIsLoading(false)
                setShowConfirmation(true)
                setTypingError(false)
                setPassword('')
                setConfPassword('')
            } catch (_) {
                setShowError(true)
                setTypingError(false)
                setShowConfirmation(false)
                setIsLoading(false)
                setPassword('')
                setConfPassword('')
            }
        } else {
            setTypingError(true)
        }
    }

    return (
        <section className="flex flex-col m-auto sm:w-1/2">
            <h1>Modifier votre mot de passe</h1>
            {showError && (
                <InfoMessage type="warning">
                    Une erreur s'est produite.
                </InfoMessage>
            )}
            {typingError && (
                <InfoMessage type="warning">
                    Vous avez tapé deux mots de passe différents.
                </InfoMessage>
            )}
            {showConfirmation ? (
                <InfoMessage type="info">
                    <p>
                        Votre mot de passe a bien été modifié, vous pouvez{' '}
                        <strong>
                            <Link to="/login">vous connecter !</Link>
                        </strong>
                    </p>
                </InfoMessage>
            ) : null}
            <form className="flex flex-col mt-4" onSubmit={modifyPassword}>
                <label htmlFor="password" className="my-2">
                    Nouveau mot de passe
                </label>
                <input
                    type="password"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    className="px-4 py-2 mb-4 rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                    value={password}
                />
                <label htmlFor="secondPassword" className="my-2">
                    Confirmer votre nouveau mot de passe
                </label>
                <input
                    type="password"
                    id="secondPassword"
                    onChange={(e) => setConfPassword(e.target.value)}
                    className="px-4 py-2 mb-4 rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                    value={confPassword}
                />
                <PrimaryButton centered clickFunc={modifyPassword}>
                    {isLoading ? (
                        <ButtonSpinner />
                    ) : (
                        'Modifier mon mot de passe'
                    )}
                </PrimaryButton>
            </form>
            <Link className="text-secondary mt-8 mx-auto" to="/login">
                Mot de passe retrouvé ? Se connecter
            </Link>
        </section>
    )
}
