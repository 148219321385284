import { useEffect, useState } from 'react'
import { gifs } from '../../../Data/gifs'
import { getRandomInt } from '../../../utils/utils'
import { Link, useLocation } from 'react-router-dom'
import PrimaryButton from '../../../Components/Buttons/PrimaryButton'

export default function Confirmation() {
    const location = useLocation()
    const [characterName, setCharacterName] = useState()
    const [words, setWords] = useState()
    const [gif, setGif] = useState(0)

    useEffect(() => {
        const randomNumber = getRandomInt(gifs.length)
        setGif(gifs[randomNumber])
        const queryParams = new URLSearchParams(location.search)
        setCharacterName(queryParams.get('character'))
        setWords(queryParams.get('wordcount'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="flex flex-col sm:flex-row m-auto justify-center items-center my-4 sm:w-2/3">
                <img
                    src={gif}
                    alt="yay"
                    className="sm:h-64 sm:w-96 h-40 w-52 object-cover sm:mr-8"
                />
                <div className="text-center flex flex-col items-center justify-center">
                    <h1>Bravo ! 🎊</h1>
                    <div className="flex flex-wrap">
                        <div className="border border-solid border-border-color rounded px-2 py-1 m-2">
                            {characterName ? characterName : 'Aucun personnage'}
                        </div>
                        <div className="border border-solid border-border-color rounded px-2 py-1 m-2">
                            {words ? words : '0'} mots
                        </div>
                    </div>
                    <p className="mb-8">
                        Les stats de ce RP ont été ajoutées à votre compte !
                        Vous pouvez voir les stats de vos RPs sur la page
                        "Statistiques".
                    </p>
                    <Link to="/statistiques" className="mb-8">
                        <PrimaryButton>Voir mes statistiques</PrimaryButton>
                    </Link>
                </div>
            </div>
        </>
    )
}
