import { createContext, useContext, useState, useEffect } from 'react'
import { themes } from '../Data/const'

const ThemeContext = createContext()

export function ThemeWrapper({ children }) {
    const [theme, setTheme] = useState('light')

    useEffect(() => {
        localStorage.getItem('airpeger-theme') &&
        themes.includes(localStorage.getItem('airpeger-theme'))
            ? setTheme(localStorage.getItem('airpeger-theme'))
            : setTheme('light')
        document.documentElement.setAttribute('class', theme)
    }, [theme])

    const updateTheme = (newTheme) => {
        setTheme(newTheme)
        localStorage.setItem('airpeger-theme', newTheme)
        document.documentElement.setAttribute('class', theme)
    }

    return (
        <ThemeContext.Provider value={{ theme, updateTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}

export function useTheme() {
    return useContext(ThemeContext)
}
