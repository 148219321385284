import Trash from '../../Assets/trash-light.svg'
import TrashDark from '../../Assets/trash-dark.svg'

import { lightThemes } from '../../Data/const'

import { useTheme } from '../../Context/theme'

import '../RPItem/RPItem.css'

export default function RPToOpenItem({ key, rp, removeRP }) {
    const { theme } = useTheme()

    const deleteRP = () => {
        removeRP(rp.name)
    }

    return (
        <>
            <div
                className="sm:w-[49%] bg-background text-main flex flex-col sm:flex-col sm:justify-between items-start sm:items-center rounded-md px-8 py-4 my-4"
                key={key}>
                <div className="flex flex-col sm:flex-row items-start sm:items-center w-full">
                    <span className="w-80px text-sm text-center text-rp-blue border border-solid border-grey py-1 bg-rp-light-blue rounded-md">
                        À ouvrir
                    </span>
                    <p className="sm:mx-4 sm:mt-0 mt-4 py-1 px-2 text-sm rounded border border-solid border-border-color w-32 truncate">
                        {rp.character || 'Aucun personnage'}
                    </p>
                    <span
                        target="_blank"
                        rel="noreferrer"
                        className="sm:ml-4 sm:my-0 my-4 rp-item-link truncate text-sm">
                        {rp.name}
                    </span>
                    <img
                        className="cursor-pointer trash-icon w-6 h-6"
                        onClick={deleteRP}
                        src={lightThemes.includes(theme) ? Trash : TrashDark}
                        alt="supprimer"
                    />
                </div>
                <div className="w-full mt-3 text-sm font-light	">
                    {rp.description}
                </div>
            </div>
        </>
    )
}
