import { useState } from 'react'
import { Link } from 'react-router-dom'

import FormSubmit from '../../../Components/Buttons/FormSubmit'
import InfoMessage from '../../../Components/InfoMessage/InfoMessage'
import { signup } from '../../../utils/utils'
import Spinner from '../../../Components/Spinner/Spinner'

export default function Signup() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [pseudo, setPseudo] = useState('')
    const [showError, setShowError] = useState(false)
    const [isLoading, setIsLoading] = useState()
    const [showConfirmation, setShowConfirmation] = useState(false)

    const createAccount = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setShowError(false)
        try {
            await signup(pseudo, password, email)
            setIsLoading(false)
            setShowConfirmation(true)
        } catch (_) {
            setShowError(true)
            setShowConfirmation(false)
            setIsLoading(false)
        }
    }

    return (
        <section className="flex flex-col m-auto sm:w-1/2">
            <h1>S'inscrire</h1>
            {showError ? (
                <InfoMessage type="warning">
                    <p>
                        Une erreur est survenue, il est possible que le pseudo
                        ou l'adresse email soit déjà pris !
                    </p>
                </InfoMessage>
            ) : null}
            {showConfirmation ? (
                <InfoMessage type="info">
                    Votre compte a bien été créé, vous pouvez désormais{' '}
                    <strong>
                        <Link to="/login">vous connecter !</Link>
                    </strong>
                </InfoMessage>
            ) : null}
            <form onSubmit={createAccount} className="flex flex-col mt-4">
                <label htmlFor="email" className="my-2">
                    Adresse email
                </label>
                <input
                    type="email"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="px-4 py-2 mb-4 rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                />
                <label htmlFor="pseudo" className="my-2">
                    Pseudo
                </label>
                <input
                    type="text"
                    id="pseudo"
                    onChange={(e) => setPseudo(e.target.value)}
                    className="px-4 py-2 mb-4 rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                />
                <label htmlFor="password" className="my-2">
                    Mot de passe
                </label>
                <input
                    type="password"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    className="px-4 py-2 mb-4 rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                />
                {isLoading ? <Spinner centered /> : null}
                <FormSubmit value="S'inscrire" />
            </form>
            <Link className="text-secondary mt-8 mx-auto" to="/login">
                Déjà un compte ? Se connecter
            </Link>
        </section>
    )
}
