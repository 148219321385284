import classNames from 'classnames'

export default function InfoMessage({ children, type, infoStyle }) {
    return (
        <div
            className={classNames(
                type === 'warning'
                    ? 'bg-light-red border-red text-red'
                    : type === 'info'
                    ? 'bg-light-blue border-blue text-blue'
                    : 'bg-background border-border-color',
                `border border-solid px-8 py-4 rounded-md mt-3 ${infoStyle}`
            )}>
            {children}
        </div>
    )
}
