export default function PrimaryButton({ children, clickFunc, centered }) {
    return (
        <button
            onClick={clickFunc}
            className={
                centered
                    ? 'w-80 py-4 border border-secondary bg-secondary text-secondary-text rounded-md m-auto mt-8'
                    : 'w-48 py-4 border border-secondary bg-secondary text-secondary-text rounded-md sm:mx-4 sm:mt-0 mt-4'
            }>
            {children}
        </button>
    )
}
