import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <footer className="bg-background text-main w-full px-8 py-8 flex sm:flex-row flex-col justify-between">
            <div className="flex sm:flex-row flex-col justify-between">
                <div className="sm:mr-16">
                    <h3>Aide</h3>
                    <ul>
                        <li>
                            <Link to="/news">Nouveautés</Link>
                        </li>
                        <li>
                            <Link to="/aide">Page d'aide</Link>
                        </li>
                        <li>
                            <a href="mailto:airpeger@gmail.com">Contact</a>
                        </li>
                    </ul>
                </div>
                <div className="sm:mt-0 mt-8">
                    <h3>Partenaires</h3>
                    <ul>
                        <li>
                            <a
                                href="https://a-warm-embrace.forumactif.com/"
                                target="_blank"
                                rel="noreferrer">
                                A Warm Embrace
                            </a>{' '}
                            ✨
                        </li>
                        <li>
                            <a
                                href="https://www.avacrea.org/"
                                target="_blank"
                                rel="noreferrer">
                                Avacréa
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://blankthemerpg.forumactif.com/"
                                target="_blank"
                                rel="noreferrer">
                                Blank Theme
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://unbreakable-vow.forumactif.org/"
                                target="_blank"
                                rel="noreferrer">
                                Unbreakable Vow
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="sm:mt-0 mt-8">
                @ kath - {new Date().getFullYear()}
            </div>
        </footer>
    )
}
