import warning from '../../Assets/warning.png'

export default function Warning({ children }) {
    return (
        <div className="border border-solid border-warning p-4 rounded-md flex my-4">
            <img src={warning} alt="attention" className="w-4 h-4 mr-4 mt-1" />
            <p className="text-justify">{children}</p>
        </div>
    )
}
