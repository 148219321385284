import React from 'react'
import { NavLink } from 'react-router-dom'

export default function HeaderLink({
    name,
    link,
    showOnMobile,
    setBurgerMenuOpen,
    burgerMenuOpen,
}) {
    const closeBurgerMenu = () => {
        burgerMenuOpen && setBurgerMenuOpen(false)
    }
    return (
        <li className={`sm:mx-3 my-4 sm:my-0 ${showOnMobile && 'sm:hidden'}`}>
            <NavLink
                onClick={closeBurgerMenu}
                className={({ isActive }) => {
                    return isActive
                        ? `bg-light-secondary px-4 py-3 mb-2 rounded-md`
                        : `px-4 py-3 mb-2 rounded-md`
                }}
                to={link}>
                {name}
            </NavLink>
        </li>
    )
}
