import React from 'react'

export default function WordCount({ count, type }) {
    return (
        <div className="flex flex-col items-center justify-center rounded-md text-secondary border border-solid border-secondary py-4 px-8 sm:mx-8 my-4 text-sm">
            <p>{count}</p>
            <p>{type === 'words' ? 'mots' : 'caractères'}</p>
        </div>
    )
}
