import React from 'react'
import CountUp from 'react-countup'
import Spinner from '../Spinner/Spinner'

export default function LandingStats({ statsLoaded, error, data, children }) {
    return (
        <div
            className="rounded-lg p-8 mx-4 my-8 drop-shadow-xl text-center bg-background text-main sm:my-0"
            data-testid="stats-block">
            {statsLoaded ? (
                error ? (
                    <h2>0</h2>
                ) : (
                    <p className="font-medium text-xl">
                        <CountUp duration={1.5} end={data} />
                    </p>
                )
            ) : (
                <Spinner centered />
            )}
            <p className="mt-4">{children}</p>
        </div>
    )
}
